/* eslint-disable no-restricted-properties */
import React, { useRef, useState, useEffect } from 'react'
import styled from 'styled-components'
import { graphql } from 'gatsby'
import { HeroBlock, FeaturedItemBlock, Space, SEO } from '../components/shared'
import { CollectionSlider, ProductSlider } from '../components/shared/sliders'
import { mobileVw, desktopBreakpoint, desktopVw, colors, fonts, letterSpacing, shortDskVw } from '../styles'
import { ContentBlock, Newsletter } from '../components/home'
import { useCreateContact, useDictionaryQuery, useLocale } from '../hooks'
import NewsletterMailchimp from '../components/newsletterMailchimp/NewsletterMailchimp'
import VideoSchema from '../components/shared/VideoSchema'
import { Social } from '../components/shared'
import { ArrowDown } from '../components/shared/icons'
const Home = ({
  location,
  data: { contentfulPageHome, contentfulGlobalSite },
  pageContext: { shopenful },
}) => {
  const {
    seoTitle,
    heroBlock,
    productSlider,
    collectionSlider,
    contentBlock,
    newsletterDecoration,
    seoDescription,
    seoImage,
    newsletterTitle,
    newsletterDescription,
    newsletterCheckbox,
    newsletterSubmit,
    newsletterButton,
    infoTitlle,
    infoSubtitle,
    productCollections
  } = contentfulPageHome
  const { currentSeason } = contentfulGlobalSite
  const locale = useLocale();
  const {
    homePageCollectionSliderSubTitle,
    homePageCollectionSliderButton,
    homePageRecipeTitle,
    homePageCollectionSliderButtonSlug
  } = useDictionaryQuery()

  useEffect(() => {
    const script = document.createElement('script')
    script.src =
      'https://productreviews.shopifycdn.com//assets//v4//spr.js?shop=maille-fr.myshopify.com'
    script.async = true
    if (locale === 'fr') {
      setTimeout(() => document.body.appendChild(script), 1000)
      return () => {
        document.body.removeChild(script)
      }
    }
  }, [])

  const [filterMenu, setFilterMenu] = useState(false)
  const handleClose = (i) => {
    setMenu(i)
    setFilterMenu(false)
  }
  const [menu, setMenu] = useState(0)
  const refContainer = useRef(null)

  const focusMenu = () => {
    {
      menu ? refContainer.current.focus() : null
    }
    viewDetails()
  }

  const viewDetails = () => {
    {
      menu
        ? window.scrollTo({
            top: refContainer.current.offsetTop - 100,
            behavior: 'smooth',
          })
        : null
    }
  }
  useEffect(() => {
    focusMenu()
  }, [menu])

  if (locale === 'de'){
    return <HomePageWrapper>
            <SEO
            title={seoTitle}
            description={seoDescription}
            image={seoImage.fluid.src}
            location={location}
            pageType='Home'
        />
        <Background>
            <GermanContent>
            <Title>{infoTitlle}</Title>
            <Subtitle>{infoSubtitle}</Subtitle>
            <StyledSocialGermany
                social={{
                    share: ['Youtube', 'Facebook'],
                    links: ['https://www.youtube.com/channel/UCQ7SIECoYKas117BHn1A0IQ', 'https://www.facebook.com/foodboommustardlovers/'],
                }}
            />
            </GermanContent>
        </Background>
    </HomePageWrapper>
  }
  return (
    <HomePageWrapper>
      <SEO
        title={seoTitle}
        description={seoDescription}
        image={seoImage.fluid.src}
        location={location}
        pageType='Home'
        googleSiteVerificationId = 'fAEd-1bDuwX-WTpa7z4T7nKxF6CyXizXfRdTjJ6J-TU'
      />
      <VideoSchema 
      schema={[{
        "@context": "https://schema.org",
        "@type": "Organization",
        "name": "Maille",
        "url": "https://maille.com/fr",
        "logo": "https://d33wubrfki0l68.cloudfront.net/93e6de77a6094eb75fd3b609f26b9ca39ac1711c/4d8cd/static/logo-ba11ec80081c18577f548f65fba89759.png",
        "sameAs": "https://www.instagram.com/maisonmaille/?hl=en"
      },
      {
        "@context": "https://schema.org/",
        "@type": "WebSite",
        "name": "Maille France",
        "url": "https://maille.com/fr",
        "potentialAction": {
          "@type": "SearchAction",
          "target": "https://maille.com/fr{search_term_string}"
        }
      }
      ]} />
      <HeroBlock data={heroBlock} fontSize={66} homePage={true} />
      {
        collectionSlider &&
        <>
          <Wrapper>
            <CollectionSlider
              titleHeading={collectionSlider.title.concat(
                homePageCollectionSliderSubTitle && homePageCollectionSliderSubTitle,
              )}
              slides={collectionSlider.slides}
              height={356}
              button={homePageCollectionSliderButton ? homePageCollectionSliderButton : 'Achetez en ligne'}
              buttonSlug={homePageCollectionSliderButtonSlug ? homePageCollectionSliderButtonSlug : '/e-boutique'}
              productCategory
            />
          </Wrapper>
          <Space l={3} s={3} />
        </>
      }
      {
        productSlider &&
        <ProductSlider
          shopenful={shopenful}
          button={productSlider.button}
          title={productSlider.title}
          products={productSlider.products}
          buttonSlug='/e-boutique/cadeaux'
          gifting={true}
        />
      }
      <Space l={3} s={3} />
      <StyledButton
            onClick={() => setFilterMenu(!filterMenu)}
            className='mobileOnly'
        >
            Menu
        <StyledArrow />
        </StyledButton>
            <Menu display={!!filterMenu}>
            {productCollections &&
                productCollections.map(({ title }, i) => (
                <CategoryMenu
                    selected={i === menu}
                    onClick={() => handleClose(i)}
                    key={i}
                >
                    <h2>{title}</h2>
                </CategoryMenu>
                ))}
            </Menu>
            {productCollections &&
            productCollections.map((collection, i) => (
                <CollectionSlider
                key={i}
                menu={menu}
                index={i}
                refContainer={refContainer}
                titleHeading={collection.title}
                slides={collection.slides}
                />
            ))}
            <Space l={3} s={9} />
            <StyledFeaturedItemBlock>
        <FeaturedItemBlock
          title={homePageRecipeTitle ? [homePageRecipeTitle.split(',')[0], homePageRecipeTitle.split(',')[1]] : ['Cuisinez', 'chaque saveur']}
          id={100}
          season={currentSeason}
          hideRating
        />
      </StyledFeaturedItemBlock>
      <Space l={3} s={9} />
      <Background>
        {contentBlock && contentBlock.map(
          (
            { title, decorations, image, description, buttonText, flexLayout },
            i,
          ) => {
            return (
              <React.Fragment key={i}>
                <ContentBlock
                  title={title}
                  decoration={decorations}
                  image={image}
                  description={description.description}
                  button={buttonText}
                  layout={flexLayout}
                  buttonSlug={buttonText.link.slug}
                />
                <Space l={3} s={2} />
              </React.Fragment>
            )
          },
        )}
        {
          (locale === 'en-CA' || locale == 'fr-CA') &&
          <NewsletterMailchimp
            decoration={newsletterDecoration}
            newsletterTitle={newsletterTitle}
            newsletterDescription={newsletterDescription}
            newsletterCheckbox={newsletterCheckbox.newsletterCheckbox}
            newsletterSubmit={newsletterSubmit}
            newsletterButton={newsletterButton}
          />
        }
        {
          newsletterTitle &&
          <Newsletter
            decoration={newsletterDecoration}
            newsletterTitle={newsletterTitle}
            newsletterDescription={newsletterDescription}
            newsletterCheckbox={newsletterCheckbox.newsletterCheckbox}
            newsletterSubmit={newsletterSubmit}
            newsletterButton={newsletterButton}
          />
        }
      </Background>
    </HomePageWrapper>
  )
}

const HomePageWrapper = styled.div`
  overflow-x: hidden;
`

const Wrapper = styled.div`
  margin-top: ${mobileVw(-20)};
  h2 {
    span {
      :nth-child(3) {
        color: ${colors.mailleGold};
        font-size: ${mobileVw(20)};
        display: block;
      }
    }
  }

  .cta_button {
    display: none;
  }

  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
    h2 {
      span {
        :nth-child(3) {
          font-size: ${desktopVw(25)};
        }
      }
    }
    .cta_button {
      display: block;
    }
  }
`

const StyledFeaturedItemBlock = styled.div`
  padding: 0 0 0 !important;
  margin: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 0 0 !important;
    margin: 0 ${desktopVw(260)};
    .featured-image {
      margin-top: 0;
      width: 100%;
      max-height: ${desktopVw(450)};
      img {
        object-position: center center !important;
      }
    }
  }
`
const Menu = styled.div.withConfig({
    shouldForwardProp: (prop) => !['display'].includes(prop),
  })`
    padding-left: ${mobileVw(16)};
    display: ${(props) => (props.display ? `block` : `none`)};
    position: relative;
    z-index: 1;
  
    @media (min-width: ${desktopBreakpoint}) {
      display: block;
      margin-top: ${desktopVw(0)};
      padding-left: ${desktopVw(130)};
    }
  `
  const StyledArrow = styled(ArrowDown)`
  width: ${mobileVw(8)};
  height: auto;
  stroke: ${colors.black};
  margin-left: ${mobileVw(5)};
  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(8)};
    margin-left: ${desktopVw(15)};
    margin-bottom: ${desktopVw(2)};
  }
`

const StyledButton = styled.button`
  margin-top: ${mobileVw(10)};
  margin-left: ${mobileVw(16)};
  width: ${mobileVw(288)};
  background-color: ${colors.mediumGrey};
  padding: ${mobileVw(22)} 0;
  text-transform: uppercase;
  font-size: ${mobileVw(20)};
  font-family: ${fonts.tivoli};
  letter-spacing: ${mobileVw(2)};
  @media (min-width: ${desktopBreakpoint}) {
    margin-top: 0;
  }
`
  const CategoryMenu = styled.div`
  cursor: pointer;
  display: inline-block;
  margin: ${mobileVw(5)} ${mobileVw(15)} 0 0;
  letter-spacing: ${mobileVw(2.2)};
  padding: ${mobileVw(4)};
  line-height: ${mobileVw(20)};
  text-transform: uppercase;
  letter-spacing: ${mobileVw(2.2)};
  border-radius: ${mobileVw(10)};
  background-color: ${(props) =>
    props.selected ? `${colors.mailleGold}` : `inherit`};
  color: ${(props) => (props.selected ? `${colors.white}` : `${colors.black}`)};
  h2 {
    font-size: ${mobileVw(18)};
  }
  @media (min-width: ${desktopBreakpoint}) {
    margin-right: ${desktopVw(24)};
    margin-bottom: 0;
    line-height: ${desktopVw(24)};
    letter-spacing: ${desktopVw(2.2)};
    background-color: inherit;
    padding: 0;
    border-radius: 0;
    color: ${(props) =>
      props.selected ? `${colors.mailleGold}` : `${colors.black}`};
    border-bottom: ${(props) =>
      props.selected
        ? `solid ${desktopVw(1.5)} ${colors.mailleGold}`
        : `solid ${desktopVw(1.5)} ${colors.white}`};
    h2 {
      font-size: ${desktopVw(22)};
    }
  }
`

const Background = styled.div`
  background: #000;
`

const GermanContent = styled.div`
  height: 40vh;
  padding: 20px 0;
  margin: 0 auto;
  width: 70%;
  @media (min-width: ${desktopBreakpoint}) {
        width: 50%;
        height: 50vh;
    }
`
const Title = styled.h1`
    color: #fff;
    text-align: center;
    font-size: ${mobileVw(20)};
    font-family: ${fonts.splandor};
    @media (min-width: ${desktopBreakpoint}) {
        font-size: ${desktopVw(44)};
    }
`
const Subtitle = styled.h2`
    color: #fff;
    font-size: ${mobileVw(15)};
    text-align: center;
    margin-top: 50px;
    font-family: ${fonts.mrs};
    @media (min-width: ${desktopBreakpoint}) {
        font-size: ${desktopVw(24)};
    }
`
const StyledSocialGermany = styled(Social)`
    margin: 50px auto;
    text-align: center;
    padding-left: 0;
  a {
    height: ${mobileVw(20)};
    margin: 0 ${mobileVw(30)} 0 0;
  }

  svg {
    height: ${mobileVw(20)};
    * {
      fill: #fff;
    }

    &:last-child {
      margin: 0;
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    a {
      height: ${desktopVw(30)};
      margin: ${shortDskVw([0, 20, 0, 0])};
    }
    svg {
      height: ${desktopVw(30)};
    }
  }
`
export const HomeQuery = graphql`
  query HomePage($id: String!, $node_locale: String!) {
    contentfulPageHome(id: { eq: $id }) {
      id
      seoTitle
      seoDescription
      infoTitlle
      infoSubtitle
      seoImage {
        fluid(maxWidth: 1800) {
          ...GatsbyContentfulFluid_withWebp_noBase64
        }
      }
      contentBlock {
        flexLayout
        title
        description {
          description
        }
        buttonText {
          title
          link {
            ... on ContentfulPageStores {
              id
              slug
            }
            ... on ContentfulPageLaMaisonMaille {
              id
              slug
            }
          }
        }
        image {
          fluid(sizes: "(max-width: 1023px) 65vw, 50vw") {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        decorations {
          image {
            fluid(maxWidth: 1800) {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      newsletterDecoration {
        image {
          fluid(maxWidth: 1800) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        positionDesktop
        positionMobile
        widthDesktop
        transformBlock
        widthMobile
        view
        name
      }
      newsletterTitle
      newsletterDescription
      newsletterCheckbox{
        newsletterCheckbox
      }
      newsletterSubmit
      newsletterButton
      collectionSlider {
        title
        slides {
          ... on ContentfulPageEBoutiqueCollection {
            slug
            title
            thumbnailImage {
              fluid(sizes: "(max-width: 1023px) 35vw, 25vw") {
                ...GatsbyContentfulFluid_withWebp_noBase64
              }
              title
            }
          }
        }
      }
      heroBlock {
        background
        marginBottom
        textSlider {
          title
          #subTitleText
          link {
            link {
              ...linkTarget
            }
          }
        }
        richDescription {
            raw
        }
        title
        video {
          name
          poster {
            fluid(sizes: "(max-width: 1023px) 50vw, 50vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
          videoMov {
            file {
              url
            }
          }
          videoMp4 {
            file {
              url
            }
          }
        }
        imagesSlider {
          file {
            url
          }
          fluid(sizes: "(max-width: 1023px) 60vw, 50vw") {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
          title
        }
        isVideoCarousel
        decoration {
          image {
            fluid(sizes: "(max-width: 1023px) 50vw, 50vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          positionDesktop
          positionMobile
          widthDesktop
          transformBlock
          widthMobile
          view
          name
        }
      }
      productCollections {
        title
        name
        slides {
            ... on ContentfulPageEBoutiqueCollection {
            slug
            title
            thumbnailImage {
                title
                fluid(maxWidth: 1800) {
                  ...GatsbyContentfulFluid_withWebp_noBase64
                }
            }
          }
        }
      }
      productSlider {
        button
        title
        products {
          promotion
          thumbnailImage {
            fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
            title
          }
          thumbnailLabel
          shopifyProduct {
            id
            handle
            variants {
              edges {
                node {
                  id
                  title
                  price{
                    amount
                  }
                }
              }
            }
          }
          title
          slug
          images {
            title
            fluid(sizes: "(max-width: 1023px) 20vw, 20vw") {
              ...GatsbyContentfulFluid_withWebp_noBase64
            }
          }
        }
      }
    }
    contentfulGlobalSite(node_locale: { eq: $node_locale }) {
      currentSeason
      node_locale
    }
  }
`

export default Home
