/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useEffect, useState } from 'react'
import styled from 'styled-components'

import 'swiper/css/swiper.css'

import {
  desktopBreakpoint,
  desktopVw,
  mobileVw,
  fonts,
  colors,
} from '../../styles'
import { Image } from '../shared/Image'
import { Button, Decoration, Space, LinkType } from '../shared'
import { useLang } from '../../hooks'

export const ContentBlock = ({
  title,
  description,
  image,
  button,
  decoration,
  layout,
  type,
  buttonSlug,
}) => {
  const lang = useLang();

  const refText = useRef(null)
  const [height, setHeight] = useState(0)

  useEffect(() => {
    setHeight(refText.current.offsetHeight)

    return () => { }
  }, [])

  return (
    <StyledContentBlock type={type}>
      <Wrapper layout={layout}>
        <TextWrapper ref={refText} layout={layout}>
          {decoration &&
            decoration.map((data, index) => {
              return (
                <React.Fragment key={index}>
                  <Space l={2} s={2} />
                  <Decoration key={data.name} data={data} />
                </React.Fragment>
              )
            })}
          <StyledTitle>
            {typeof title === 'string'
              ? title
              : title.map((word) => <span key={word}>{word}</span>)}
          </StyledTitle>
          <Description>{description}</Description>
          {!!layout && (
            <StyledFlexButton
              to={buttonSlug ? `/${lang}${buttonSlug}` : ''}
              text={button.title}
            />
          )}
        </TextWrapper>
        <StyledLinkType
          height={height}
          layout={layout}
          type={type}
          to={buttonSlug ? `/${lang}${buttonSlug}` : ''}
          alt={`Ce lien va ouvrir une nouvelle fenêtre: ${button.title}`}
        >
          <StyledImage type={type} fluid={image.fluid} alt={image.title} />
        </StyledLinkType>
        <ButtonWrapper layout={layout}>
          <Space l={1} s={1} />
          <StyledButton
            layout={layout}
            text={button.title}
            to={buttonSlug ? `/${lang}${buttonSlug}` : ''}
          />
        </ButtonWrapper>
      </Wrapper>
    </StyledContentBlock>
  )
}

const StyledContentBlock = styled.div`
  padding: 0 0;
  /* background: #000; */
  position: relative;
  color: #fff;

  @media (min-width: ${desktopBreakpoint}) {
    padding: 0 ${desktopVw(128)} 0
      ${(props) => (props.type ? 0 : desktopVw(128))};
  }
`

const Wrapper = styled.div.withConfig({
  shouldForwardProp: (prop) => !['layout'].includes(prop),
})`
  margin: 0 ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
    grid-template-columns: 1fr ${desktopVw(350)};
    grid-gap: ${desktopVw(125)};
    display: ${(props) => (props.layout ? 'grid' : 'block')};
  }
`

const StyledTitle = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(30)};
  margin: 0 0 ${mobileVw(15)} 0;

  span {
    &:first-child {
      color: ${colors.mailleGold};
      padding-right: ${mobileVw(5)};
    }
  }

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(45)};
    margin: 0 0 ${desktopVw(10)};

    span {
      &:first-child {
        padding-right: ${desktopVw(8)};
      }
    }
  }
`

const Description = styled.p`
  font-family: ${fonts.mrs};
  font-size: ${mobileVw(16)};
  margin: 0 0 ${mobileVw(20)} 0;
  line-height: ${mobileVw(18)};

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(18)};
    margin: 0 0 ${desktopVw(25)} 0;
    line-height: ${desktopVw(24)};
  }
`

const StyledImage = styled(Image)`
  margin: 0;
  width: 100%;

  @media (min-width: ${desktopBreakpoint}) {
    width: 100%;
  }
`

const StyledLinkType = styled(LinkType).withConfig({
  shouldForwardProp: (prop) => !['layout', 'type'].includes(prop),
})`
  display: block;
  height: ${(props) => `calc(100vh - ${props.height + 150}px)`};
  min-height: ${mobileVw(150)};
  margin: 0;
  width: 100%;
  max-height: ${mobileVw(450)};

  @media (min-width: ${desktopBreakpoint}) {
    height: ${desktopVw(450)};
    min-height: inherit;

    width: unset;
    ${(props) =>
    props.layout &&
    `
      width: ${desktopVw(635)};
      order: -1;
    `};
    ${(props) =>
    props.type &&
    `
      width: 100% !important;
    `};
  }
`

const StyledButton = styled(Button).withConfig({
  shouldForwardProp: (prop) => !['layout'].includes(prop),
})`
  margin: 0 0 0;
  display: block;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 auto 0;
    width: ${desktopVw(350)};
    display: ${(props) => (props.layout ? 'none' : 'block')};
  }
`

const StyledFlexButton = styled(Button)`
  margin: ${mobileVw(16)} 0 0;
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0;
    width: ${desktopVw(350)};
    margin: ${desktopVw(40)} 0 0 0;
    display: block;
  }
`

const TextWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    width: unset;
    margin-top: ${(props) => props.layout && desktopVw(132)};
  }
`

const ButtonWrapper = styled.div`
  @media (min-width: ${desktopBreakpoint}) {
    display: ${(props) => (props.layout ? 'none' : 'block')};
  }
`
