/* eslint-disable react/jsx-props-no-spreading */
import React from 'react'
import styled from 'styled-components'

import 'swiper/css/swiper.css'
import {
  desktopBreakpoint,
  desktopVw,
  mobileVw,
  fonts,
  colors,
} from '../../styles'
import { Image } from '../shared/Image'
import { Button, Decoration, EmailForm } from '../shared'

export const Newsletter = ({
  decoration,
  newsletterTitle,
  newsletterDescription,
  newsletterCheckbox,
  newsletterSubmit,
  newsletterButton,
}) => {
  const title = newsletterTitle.split(',')
  return (
    <StyledNewsletter>
      <Content>
        <StyledTitle>
          <span>{title[0]}</span>{title[1]}
        </StyledTitle>
        {/* <Description>
          {newsletterDescription}
        </Description> */}
        <Wrapper aria-label='Homepage newsletter form'>
          <EmailForm
            newsletterCheckbox={newsletterCheckbox}
            newsletterSubmit={newsletterSubmit}
            newsletterButton={newsletterButton}
            form='homepage'
            formId='homepage-newsletter-form'
          />
        </Wrapper>
      </Content>

      {decoration &&
        decoration.map((data, i) => {
          return <Decoration key={i} data={data} />
        })}
    </StyledNewsletter>
  )
}

const StyledNewsletter = styled.div`
  text-align: center;
  color: #fff;
  background: #000;
  position: relative;
  padding: ${mobileVw(50)} ${mobileVw(16)} ${mobileVw(175)};
  display: none;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(50)} 0;
    overflow: hidden;
    display: block;
  }
`

const Content = styled.div`
  position: relative;
  z-index: 1;
`

const Wrapper = styled.form`
  width: auto;
  margin: 0 auto;

  @media (min-width: ${desktopBreakpoint}) {
    width: ${desktopVw(350)};
  }
`

const StyledTitle = styled.h2`
  font-family: ${fonts.splandor};
  font-size: ${mobileVw(40)};
  line-height: ${mobileVw(32)};
  margin: 0 0 ${mobileVw(15)} 0;

  @media (min-width: ${desktopBreakpoint}) {
    font-size: ${desktopVw(44)};
    line-height: ${desktopVw(40)};
    margin: 0 0 ${desktopVw(12)};
  }

  span {
    display: inline-block;

    &:first-child {
      color: ${colors.mailleGold};
    }
  }
`

const Description = styled.p`
  font-family: ${fonts.mrs};
  line-height: ${mobileVw(20)};
  margin: 0 0 ${mobileVw(16)} 0;
  font-size: ${mobileVw(16)};

  @media (min-width: ${desktopBreakpoint}) {
    line-height: ${desktopVw(24)};
    margin: 0 0 ${desktopVw(25)} 0;
    font-size: ${desktopVw(18)};
  }
`

const Input = styled.input`
  font-family: ${fonts.mrs};
  width: 100%;
  padding: ${mobileVw(10)} ${mobileVw(16)};
  font-size: ${mobileVw(14)};
  background: transparent;
  outline: none;
  border: ${mobileVw(0.5)} solid #fff;
  margin: 0 0 ${mobileVw(20)} 0;
  color: #fff;

  @media (min-width: ${desktopBreakpoint}) {
    padding: ${desktopVw(15)};
    font-size: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid #fff;
    margin: 0 0 ${desktopVw(20)} 0;
  }

  &::placeholder {
    color: white;
  }
`

const Statement = styled.div`
  margin: 0 0 ${mobileVw(12)} 0;
  text-align: left;
  display: flex;
  display: grid;
  grid-template-columns: auto auto;
  font-size: ${mobileVw(12)};

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 0 ${desktopVw(20)} 0;
    font-size: ${desktopVw(14)};
  }
`

const StyledCheckbox = styled.input`
  background: transparent;
  border-radius: 0;
  display: block;
  margin: 0 ${mobileVw(7)} 0 0;
  width: ${mobileVw(16)};
  height: ${mobileVw(16)};
  border: ${mobileVw(0.5)} solid #fff;

  @media (min-width: ${desktopBreakpoint}) {
    margin: 0 ${desktopVw(12)} 0 0;
    width: ${desktopVw(16)};
    height: ${desktopVw(16)};
    border: ${desktopVw(0.5)} solid #fff;
  }
`
